
var pickerObj;

function picker(el){

  // Creatting
  this.createPicker = function(el) {
    var select = el.querySelector('.picker__select'),
        inner = el.querySelector('.picker__inner'),
        header = document.createElement('button'),
        span = document.createElement('span'),
        spanText = '--',
        drop = document.createElement('div'),
        variants = document.createElement('ul');
    
    // header
    header.classList.add('picker__header');
    header.setAttribute('type', 'button');

    if (select.value) {
      spanText = select.options[select.selectedIndex].text;
    } else if (select.options[0].hidden) {
      spanText = select.options[0].text;
    }

    span.textContent = spanText;
    header.appendChild(span);
    
    // drop
    drop.classList.add('picker__drop');

    if (el.hasAttribute('data-search')) {
      drop.innerHTML = 
        '<div class="picker__search-wrp"> \
          <input class="picker__search-input" type="text" placeholder="Начините вводить название"> \
          <button class="picker__search-clear-btn" type="button"></button> \
        </div>';
    }

    // list
    variants.classList.add('picker__list', 'thin-scroll');
    variants.innerHTML = '';
    
    if ( select.hasAttribute('data-header') ) {
      variants.classList.add('picker__list--with-headers');
    }

    [].forEach.call(select.options, function(option){
      var li;

      if (option.hasAttribute('hidden')) {
        return;
      }

      li = document.createElement('li');
      li.classList.add('picker__list-item');

      if (option.hasAttribute('data-header')) {
        li.classList.add('picker__list-item--header');
        li.innerHTML = '<div class="check"> \
                          <span class="check__descr">'+option.text+'</span> \
                        </div>';
      } else if (option.hasAttribute('data-header-link')) {
        li.classList.add('picker__list-item--header-link');
        li.innerHTML = '<label class="check"> \
                          <input type="checkbox" class="check__input" value="'+option.value+'"> \
                          <span class="check__descr">'+option.text+'</span> \
                        </label>';
      } else {
        li.innerHTML = '<label class="check"> \
                          <input type="checkbox" class="check__input" value="'+option.value+'"> \
                          <span class="check__descr">'+option.text+'</span> \
                        </label>';
      }

      for (i = 0; i < option.attributes.length; i++) {
        var a = option.attributes[i];
        if (a.name != 'value') {
          li.setAttribute(a.name, a.value);
        }
      }
      
      variants.appendChild(li);
    });
    
    drop.appendChild(variants);
    inner.appendChild(header);
    inner.appendChild(drop);    
  },

  this.rebuildPicker = function(el) {
    var listWrp = el.querySelector('.picker__inner');
    listWrp.innerHTML = '';
    this.createPicker(el);
  },

  this.init = function() {
    var pickers = document.querySelectorAll('.picker');
    var plugin = this;
    [].forEach.call(pickers, function(picker){
      plugin.createPicker(picker);
    });
  }
}

document.addEventListener('DOMContentLoaded', function(){

  pickerObj = new picker();
  pickerObj.init();

  // Toggle
  document.addEventListener('click', function(e){
    var target = e.target.closest('.picker__header');
    
    if (!target || target.disabled) {
      return;
    }
    
    var wrp = target.closest('.picker');
    
    if (wrp.classList.contains('picker--error')) {
      wrp.classList.remove('picker--error');
    }

    if (wrp.classList.contains('picker--opened')){
      wrp.classList.remove('picker--opened')
    } else {

      var openedPicker = document.querySelector('.picker--opened');
      if (openedPicker){
        openedPicker.classList.remove('picker--opened');
      }

      wrp.classList.add('picker--opened')
    }
  });

  // Close in outclick
  document.addEventListener('click', function(e){
    var target = e.target.closest('.picker');
    
    if (!target) {
      var opened = document.querySelector('.picker--opened');
      if (opened) {
        opened.classList.remove('picker--opened');
      } 
    }    
  });

  // close by esc
  document.addEventListener('keyup', function(e){
    
    if(e.key === "Escape") {

      var opened = document.querySelector('.picker--opened');
      if (opened) {
        opened.classList.remove('picker--opened');
        opened.querySelector('.picker__header').focus();
      } 
    }
  });

  // Picking variant
  document.addEventListener('change', function(e){

    var input = e.target,
        line = input.closest('.picker__list-item');

    if (input.classList.contains('check__input') && line) {

      var descr = line.querySelector('.check__descr').textContent,    
          value = input.value,
          wrp = line.closest('.picker'),
          header = wrp.querySelector('.picker__header span'),
          nativeSelect = wrp.querySelector('.picker__select');

      if (wrp.classList.contains('picker--single')) {

        nativeSelect.value = value;
        header.textContent = descr;

        wrp.classList.remove('picker--opened');

      } else {

        // multi
        for (var i = 0; i < nativeSelect.options.length; i++) {        
          if (nativeSelect.options[i].value == value ) {
            nativeSelect.options[i].selected = !nativeSelect.options[i].selected;
            break;
          }
        }

        var count = 0;
        for (var i=0; i < nativeSelect.options.length; i++) {
          if (nativeSelect.options[i].selected) {
            count++;
          }
        }

        if (count > 1) {
          header.textContent = count + ' выбрано';
        } else if (!count) {
          header.textContent = '--';
        } else {
          header.textContent = descr;
        }
      }

      var event = new Event('change', {bubbles: true});
      nativeSelect.dispatchEvent(event);
    }

  });

  // Search items
  document.addEventListener('keyup', function(e){
    var input = e.target;

    if (!input.classList.contains('picker__search-input')){
      return;
    }

    var filter = input.value.toUpperCase(),
        wrp = input.closest('.picker__drop'),
        variants = wrp.querySelectorAll('.check__descr');
        
    for (var i = 0; i < variants.length; i++) {
      var txtValue = variants[i].textContent || variants[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        variants[i].closest('.picker__list-item').style.display = '';
      } else {
        variants[i].closest('.picker__list-item').style.display = 'none';
      }
    }    
  });

  document.addEventListener('click', function(e){
    var btn = e.target;
    if (!btn.classList.contains('picker__search-clear-btn')) {
      return;
    }

    var input = btn.closest('.picker__search-wrp').querySelector('.picker__search-input');
    input.value = '';

    var event = document.createEvent('Event');
    event.initEvent('keyup', true, true);
    input.dispatchEvent(event);
  });
});
